import React from 'react'
import { Link  } from 'react-router-dom'
const Services = () => {
  return (
    <section className="flex flex-col bg-background py-8 md:py-14">
        <div className="container flex flex-col gap-y-4 md:gap-y-8">
            <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-end md:justify-between">
            <h2 className="max-w-xl text-3xl font-medium tracking-tighter text-foreground md:text-4xl lg:text-6xl">
                <span className="headline-text">What I excel at when it comes to Shopify.</span>
            </h2>
            </div>
            <div dir="ltr" data-orientation="horizontal" class="w-full">
            {/* <div role="tablist" aria-orientation="horizontal" class="inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground mb-4" tabindex="0" data-orientation="horizontal" style={{outline:'none'}}>
                <button type="button" role="tab" aria-selected="true" aria-controls="radix-:Ra8p:-content-frontend" data-state="active"  id="radix-:Ra8p:-trigger-frontend" class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow" tabindex="0" data-orientation="horizontal" data-radix-collection-item="">Frontend</button>
                <button type="button" role="tab" aria-selected="false" aria-controls="radix-:Ra8p:-content-backend" data-state="inactive" id="radix-:Ra8p:-trigger-backend" class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow" tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">Backend</button>
                <button type="button" role="tab" aria-selected="false" aria-controls="radix-:Ra8p:-content-consultancy" data-state="inactive" id="radix-:Ra8p:-trigger-consultancy" class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow" tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">Consultancy</button>
            </div> */}
            <div data-state="active" data-orientation="horizontal" role="tabpanel" aria-labelledby="radix-:Ra8p:-trigger-frontend" id="radix-:Ra8p:-content-frontend" tabindex="0" class="mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2">
                <ul className="flex flex-col divide-y divide-foreground/10" >
                <li className="flex bg-background text-foreground transition-colors duration-200 first:rounded-t-md last:rounded-b-md hover:bg-primary hover:text-primary-foreground">
                    <Link className="flex flex-col p-4 md:px-8 md:py-6" to="/">
                    <div class="grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-6">
                        <h3 class="col-span-2 text-xl font-medium tracking-tight md:text-3xl">
                        <span className="headline-text">Shopify Theme from the ground up.</span>
                        </h3>
                        <p class="col-span-1 text-base tracking-tight md:col-span-2 md:col-start-5 md:text-justify md:text-lg">Building a storefront on top of Shopify native Online Store using my own Custom Starter.</p>
                    </div>
                    </Link>
                </li>
                <li class="flex bg-background text-foreground transition-colors duration-200 first:rounded-t-md last:rounded-b-md hover:bg-primary hover:text-primary-foreground">
                    <Link class="flex flex-col p-4 md:px-8 md:py-6" to="/" >
                    <div class="grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-6">
                        <h3 class="col-span-2 text-xl font-medium tracking-tight md:text-3xl">
                        <span className="headline-text">Headless Commerce on Shopify Plus.</span>
                        </h3>
                        <p class="col-span-1 text-base tracking-tight md:col-span-2 md:col-start-5 md:text-justify md:text-lg">Decouple the storefront from the Shopify backend and easily integrate with third-party tools or APIs, while still retaining control over content outside of Shopify.</p>
                    </div>
                    </Link>
                </li>
                <li class="flex bg-background text-foreground transition-colors duration-200 first:rounded-t-md last:rounded-b-md hover:bg-primary hover:text-primary-foreground">
                    <Link class="flex flex-col p-4 md:px-8 md:py-6" to="/" >
                    <div class="grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-6">
                        <h3 class="col-span-2 text-xl font-medium tracking-tight md:text-3xl">
                        <span className="headline-text">Complex commerce UI patterns and sections.</span>
                        </h3>
                        <p className="col-span-1 text-base tracking-tight md:col-span-2 md:col-start-5 md:text-justify md:text-lg">Building unique and complex sections on top of Shopify, such as questionnaires or custom product configurators and carts that will delight your customers and drive sales.</p>
                    </div>
                    </Link>
                </li>
                <li className="flex bg-background text-foreground transition-colors duration-200 first:rounded-t-md last:rounded-b-md hover:bg-primary hover:text-primary-foreground">
                    <Link className="flex flex-col p-4 md:px-8 md:py-6" to="/">
                    <div className="grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-6">
                        <h3 className="col-span-2 text-xl font-medium tracking-tight md:text-3xl">
                        <span className="headline-text">Performance, accessibility and technical SEO.</span>
                        
                        </h3>
                        <p className="col-span-1 text-base tracking-tight md:col-span-2 md:col-start-5 md:text-justify md:text-lg">Ensuring proper information architecture and navigation across all pages while keeping an eye on Core Web Vitals and storefront a11y.</p>
                    </div>
                    </Link>
                </li>
                <li className="flex bg-background text-foreground transition-colors duration-200 first:rounded-t-md last:rounded-b-md hover:bg-primary hover:text-primary-foreground">
                    <Link className="flex flex-col p-4 md:px-8 md:py-6" to="/" >
                    <div className="grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-6">
                        <h3 className="col-span-2 text-xl font-medium tracking-tight md:text-3xl">
                        <span className="headline-text">Checkout customizations and UI extensions on Shopify Plus.</span>

                        </h3>
                        <p className="col-span-1 text-base tracking-tight md:col-span-2 md:col-start-5 md:text-justify md:text-lg">Tailoring the Shopify Checkout Experience by enhancing the User Interface from Customer Details to Post-Order Status Page.</p>
                    </div>
                    </Link>
                </li>
                </ul>
            </div>
            <div data-state="inactive" data-orientation="horizontal" role="tabpanel" aria-labelledby="radix-:Ra8p:-trigger-backend" id="radix-:Ra8p:-content-backend" tabindex="0" className="mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2" hidden=""></div>
            <div data-state="inactive" data-orientation="horizontal" role="tabpanel" aria-labelledby="radix-:Ra8p:-trigger-consultancy" id="radix-:Ra8p:-content-consultancy" tabindex="0" className="mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2" hidden=""></div>
            </div>
        </div>
    </section>
  )
}

export default Services
import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
const Tagline = () => {
  return (
    <section class="flex flex-col border-b border-foreground/10 bg-background py-12 text-foreground md:py-28">
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} class="container flex flex-col items-start gap-y-14  md:gap-y-16">
            <h2 class="max-w-4xl text-4xl font-medium tracking-tighter md:text-6xl">
                <span class="headline-text">
                    Don't settle for a cookie-cutter template – let's work together to create a tailored shopping experience that will set your brand apart.
                </span>
            </h2>
            <Link class="inline-flex text-center items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-10 rounded-md px-8" to="https://www.linkedin.com/in/saifswl86" target="_blank">
                Reach Out Today
            </Link>
        </motion.div>
    </section>
  )
}

export default Tagline
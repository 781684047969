import React from 'react'
import { Link} from 'react-router-dom'
import { Builder } from '@builder.io/react'
import { motion } from 'framer-motion'


const Header = ({btnTitle,btnLink, linkedInLink }) => {
  return (
    <header className="flex justify-between p-5 container">
            <div className="header__info">
                <div class="mr-4 flex">
                  <Link 
                   
                  to="/" className="mr-6 flex items-center space-x-2">
                    <div className="inline-flex h-9 select-none items-center gap-x-2 rounded-md border border-foreground-20 bg-background-90 px-3 py-2">
                      <h2 className="rounded-sm border border-foreground-10 bg-primary px-1.5 font-mono text-sm uppercase text-primary-foreground">Saif </h2>
                      <h2 className="font-sans text-lg font-medium uppercase text-foreground/90">Ur Rehman</h2>
                    </div>
                  </Link>
                  {/* <nav class="hidden items-center space-x-6 text-sm font-medium md:flex">
                    <Link class="transition-colors hover:text-foreground/80 text-foreground/60" to="https://www.linkedin.com/in/saifswl86" target="_blank">
                      Linkedin
                    </Link>
                    <Link class="transition-colors hover:text-foreground/80 text-foreground/60" to="/portfolio">
                      Portfolio
                    </Link>
                  </nav> */}
                </div>
            </div>
            <nav>
                <ul className="flex">
                    <div class="mr-4 flex">
                  <nav class="hidden items-center space-x-6 text-sm font-medium md:flex">
                    <Link class="transition-colors hover:text-foreground/80 text-foreground/60" to="https://www.linkedin.com/in/saifswl86" target="_blank">
                      Linkedin
                    </Link>
                    <Link 
                    
                    to={btnLink ? btnLink : "https://calendly.com/saif-ur-rehmann/30min" } target='_blank' className="mr-6 flex items-center space-x-2">
                    <div className="inline-flex bg-primary h-9 select-none items-center gap-x-2 rounded-md border border-foreground-20 bg-background-90 px-3 py-2">
                      <motion.h2 whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: .95 }} className="rounded-sm border border-foreground-10 px-1.5 text-primary-foreground">{ btnTitle ? btnTitle : "Schedule a call"}</motion.h2>
                    </div>
                  </Link>
                  </nav>
                </div>
                </ul>
            </nav>
        </header>
  )
}

export default Header


Builder.registerComponent(Header, { 
  name: 'Header',
  inputs: [
    { name: 'btnTitle', type: 'text' },
    { name: 'btnLink', type: 'text' },
    { name: 'linkedInLink', type: 'text' },
  
  ],
})
import React from 'react'
import { Link } from 'react-router-dom'
const Project = ({name, link, imgSrc, shopifyplus, category}) => {
  return (
        <div className="relative mx-2 inline-flex w-48 flex-col items-center justify-center bg-background shadow-sm md:mx-4 md:w-64">
            <div className="absolute left-2 top-2 z-10 flex flex-col gap-2">
            {shopifyplus && 
                <div className="inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80">Shopify Plus</div>
            }
            <div className="inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80">{ category }</div>
            </div>
            <Link className="flex w-full flex-col gap-2" to={link} target="_blank">
             <img src={imgSrc} alt={ name } className="aspect-[9/16] h-auto w-full max-w-xl rounded-md object-cover" />
             <h3 className="text-sm text-muted-foreground">{name}</h3>
            </Link>
        </div>
  )
}

export default Project
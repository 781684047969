
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";

// Put your API key here
// builder.init("96524abd6b9547488434ee957e890969");
builder.init("3bb950d01a0245d0b98906b0b39931be");

// set whether you're using the Visual Editor,

// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  let location = useLocation();

  // get the page content from Builder
   useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: location.pathname
        })
        .promise();
      console.log(content)
      setContent(content);
      setNotFound(!content);

      // if the page title is found, 
      // set the document title
      if (content?.data.title) {
       document.title = content.data.title
      }
    }
    fetchContent();
  }, [location.pathname]);
  
  // If no page is found, return 
  // a 404 page from your code.
  // The following hypothetical 
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewingInBuilder) {
    return <h1>Not Found</h1>
  }

  // return the page when found
  return (
    <div onMouseMove={
      function(e){
            document.documentElement.style.setProperty (
              '--x', (
                e.clientX+window.scrollX
              )
              + 'px'
            );
            document.documentElement.style.setProperty (
              '--y', (
                e.clientY+window.scrollY
              ) 
              + 'px'
            );
      }
    }>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
      <div id="invertedcursor"></div>
    </div>
  );
}
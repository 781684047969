import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'
const Headline = () => {
  return (
    <section className="grid items-center bg-background py-8 md:py-12">
      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }} 
        className="container flex flex-col items-start gap-6 lg:gap-12">
          <h1 className="scroll-m-20 text-4xl font-bold tracking-tight drop-shadow-md md:text-8xl">
            <span className="headline-text">I help you with Shopify Tech stuff so you can focus on growing your business.</span>
          </h1>
          <h2 className="text-lg font-normal tracking-tight text-foreground md:text-4xl">
            <span className='headline-text'>A Shopify developer crafting captivating and highly functional storefronts &amp; themes. Constantly pushing the limits of what can be achieved on the Shopify platform with code.</span>
          </h2>
          <Link 
          className="inline-flex text-center items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-10 rounded-md px-8 md:h-16 md:rounded-xl md:px-20 md:text-xl" 
          to="mailttoo:saifswl86@gmail.com">
            Reach Out
          </Link>
      </motion.div>
    </section>
  )
}

export default Headline
import './index.css';
import App from './App';
import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Builder from './pages/Builder'
// import Portfolio from './components/Portfolio'
import Section from './pages/Section'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App onmouseover={(e)=>{
      console.log(e.target)
    }} />
  },
  {
    path:'/test-page',
    element: <Builder />
  },
  {
    path:'/announcement-bar',
    element: <Section />
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
)



import React from 'react'
import Project from './Project'
import { Builder } from '@builder.io/react'
import Marquee from 'react-fast-marquee'
const Projects = () => {
  return (
    <Marquee className="flex">
        <Project name="Humble Power" category="Apparel" shopifyplus={ false } link="https://www.humblepower.ae/" imgSrc="https://www.humblepower.ae/cdn/shop/files/banner_hp.jpg?v=1725377751&width=1000" />
        <Project name="Ndure" category="Shoes" shopifyplus={ false } link="https://ndure.com" imgSrc="https://www.ndure.com/cdn/shop/files/Desktop-1920x1080-3_09551776-f201-4a81-a0eb-4b22008c44ae.jpg?v=1698986934" />
        <Project name="Ethnic" category="Apparel" shopifyplus={ true } link="https://ethnic.pk" imgSrc="https://shop.ethnic.pk/cdn/shop/files/58_d4f1a368-0739-420b-bc10-ff86dc9d18b9.jpg?v=1698903965" />
        <Project name="Alkaram Studio" category="Apparel" shopifyplus={ true } link="https://www.alkaramstudio.com" imgSrc="//www.alkaramstudio.com/cdn/shop/files/Mobile-slider_3.png" />
        <Project name="Insignia" category="Apparel" shopifyplus={ false } link="https://www.alkaramstudio.com" imgSrc="https://insignia.com.pk/cdn/shop/files/tiles_news_copy_3.jpg?v=1722854916" />
        <Project name="HiStreet" category="Apparel" shopifyplus={ false } link="https://histreet.pk/" imgSrc="https://histreet.pk/cdn/shop/files/1_eda7bfa9-fe7e-4654-9e3c-6d4256dedc8a.jpg?v=1699011869" />
        <Project name="Marium N Maria" category="Apparel" shopifyplus={ false } link="https://maryumnmaria.com/" imgSrc="https://maryumnmaria.com/cdn/shop/files/Artboard26_fc0f6212-6243-4b49-a63a-3cf8f01e1b32_900x.webp?v=1722845451" />
    </Marquee>
  )
}

export default Projects
Builder.registerComponent(Projects, { 
  name: 'Projects',
  inputs: [
    { name: 'btnTitle', type: 'text' },
    { name: 'btnLink', type: 'text' },
    { name: 'linkedInLink', type: 'text' },
  
  ],
})

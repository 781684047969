import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => { 
  return (
    <footer test className="grid min-h-[60vh] items-center bg-primary pt-8 text-primary-foreground md:pt-20">
        <div className="container flex justify-between gap-y-4">
            <h3 className="flex max-w-md flex-1 flex-col text-xl font-medium leading-tight tracking-tighter md:text-3xl lg:text-5xl">
                <span>Based in Lahore, Pakistan</span>
                <Link className="transition-colors hover:text-primary-foreground/70" to="mailttoo:saifswl86@gmail.com">contact@saifurehman.com</Link>
            </h3>
            <ul className="flex flex-col items-end justify-start gap-0 md:flex-row md:items-start md:justify-end md:gap-4" >
                <li>
                    <Link className="text-lg leading-tight transition-colors hover:text-primary-foreground/70 md:text-2xl" to="https://twitter.com/saifswl86" target="_blank">Twitter</Link>
                </li>
                <li>
                    <Link className="text-lg leading-tight transition-colors hover:text-primary-foreground/70 md:text-2xl" to="https://linkedin.com/in/saifswl86" target="_blank">LinkedIn</Link>
                </li>
                <li>
                    <Link className="text-lg leading-tight transition-colors hover:text-primary-foreground/70 md:text-2xl" to="https://github.com/saif-ur-rehman-shopify-expert" target="_blank">GitHub</Link>
                </li>        
            </ul>
        </div>
        <h3 className="mt-0 md:mt-48  container text-[10.8vw]/[1.2] font-normal uppercase tracking-[2px] md:tracking-[-0.5rem] drop-shadow-lg md:tracking-[-1rem]">SAIF UR REHMAN</h3>
    </footer>
  )
}

export default Footer
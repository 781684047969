import { useEffect, useState } from "react";
import { BuilderComponent, builder } from "@builder.io/react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
// Replace with your Public API Key.
builder.init("96524abd6b9547488434ee957e890969");

export default function Page() {
  const [announcement, setAnnouncement] = useState(null);
  let location = useLocation();
  console.log(location)
  useEffect(() => {
    builder
      .get("announcement-bar", {
        userAttributes: {
          // To allow targeting different announcements at different pages (URLs)
          urlPath: location.pathname,
        },
      })
      .toPromise()
      .then((announcementBar) => {console.log(announcementBar);setAnnouncement(announcementBar)});
  }, [location.pathname]);
  return (
    <>
      {/* Put your header here. */}
      <Header />
      {announcement && (
        <BuilderComponent model="announcement-bar" content={announcement} />
      )}
      {/* Put the rest of your page here. */}
    </>
  );
}

import './App.css';
import Header from './components/Header'
import Headline from './components/Headline'
import Tagline from './components/Tagline'
import Services from './components/Services';
import Projects from './components/Projects';
import Footer from './components/Footer';
import { Analytics } from '@vercel/analytics/react';
function App() {
  return (
    <div className="App " onMouseMove={
      function(e){
            document.documentElement.style.setProperty (
              '--x', (
                e.clientX+window.scrollX
              )
              + 'px'
            );
            document.documentElement.style.setProperty (
              '--y', (
                e.clientY+window.scrollY
              ) 
              + 'px'
            );
      }
    }>
      <div id="invertedcursor"></div>
    <div class="background">
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
   <span></span>
</div>
      <Header />
      <Headline />
      <Projects />
      <Tagline />
      <Services />
      <Footer />
      <Analytics />

    </div>
  );
}

export default App;
